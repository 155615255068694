@import "src/styles/vars";
@import "src/styles/mixins";

.wrapperClose {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 6;
  width: 32px;
  height: 32px;
  cursor: pointer;
  & .close {
    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 3px;
      height: 32px;
      background: black;
      // right: 30px;
      top: 50%;
      right: 50%;
      border-radius: 5px;
      transition: 0.3s;
    }
    &::before {
      transform: translateY(-50%) rotate(45deg);
    }
    &::after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}

@import "src/styles/vars";

.root {
  padding: 0 30px;
  margin-top: 10px;

  & .totalSum {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 15px;
    span {
      margin-top: 2px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $grey;
    }
    p {
      margin-left: 8px;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: $colorTextDarkgrey;
    }
  }
}

@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 400px;
  @include wrapper-modal-div;
  padding: 50px 60px 40px;
}

.wrapperTitle {
  width: 100%;
  display: flex;
  justify-content: center;
}

.text {
  margin-top: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  padding: 9px 20px 9px 10px;
  color: $colorTextDarkgrey;
}

@import "src/styles/vars";

.slider {
  // object-fit: cover;
  user-select: none;
  position: relative;
  & .btnPrev,
  & .btnNext {
    z-index: 10;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #fafafa !important;
    &:hover {
      background: #fafafa !important;
    }
  }
  & .btnPrev {
    left: 150px;
  }
  & .btnNext {
    right: 550px;
  }
}

.productImgSlider {
  outline: none;
  height: 100%;
  width: 100%;

  max-width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
}

.strictSizesImg {
  max-height: 750px;
}
@media screen and (max-width: $lg) {
  .slider {
    & .btnPrev {
      left: 0;
    }
    & .btnNext {
      right: 0;
    }
  }
}

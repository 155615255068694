@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 442px;
  @include wrapper-modal-div;
  padding: 54px 40px;
}

.wrapper {
  max-width: 420px;
  width: 100%;

  & .subTitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: $colorTextDarkgrey;
    margin-top: 20px;
  }
  & .products {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    width: 100%;
    & .product {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 11px;

      & .productImg {
        width: 38px;
        height: 38px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      & .productName {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $grey;
      }
    }
  }

  & .form {
    margin-top: 25px;
  }
  & .addPhoto {
    margin-top: 10px;
    display: flex;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: $colorTextDarkgrey;

    img {
      margin-right: 10px;
    }

    input {
      display: none;
    }
  }
}
.wrapperInputs {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}

.wrapperTitle {
  width: 100%;
  display: flex;
  justify-content: center;
}

.lableInner {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
  text-transform: uppercase;
  color: #a4a4a4;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
}

.btnInner {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-left: 5px;
  }
}

@import "src/styles/vars";

.root {
  max-width: 1660px;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (max-width: $xl) {
  .root {
    max-width: 100%;
    padding: 0 20px;
  }
}

@media screen and (max-width: $lg) {
  .root {
    padding: 0 10px;
  }
}

// @media screen and (max-width: $md) {
//   .root {
//     max-width: 556px;
//     padding: 0 30px;
//   }
// }

// @media screen and (max-width: $mdMinus){
//   .root{
//     max-width: 556px;
//     padding: 0 5px;
//   }
// }

// @media screen and (max-width: $sm){
//   .root{
//     padding: 0 15px;
//     width: 100%;
//   }
// }

@import "src/styles/mixins";
@import "src/styles/vars";

.styledDiv {
  position: relative;
}
.root {
  width: 100%;
}
.wrapper {
  width: 100%;
}
.select {
  border: 1px solid $colorBorderLightGrey;
  background: white;
  outline: none;
  padding: 11px 20px;
  font-size: 14px;
  position: relative;
  letter-spacing: 0.05em;
  color: $grey;
  box-shadow: none;
  // position: relative;
  &.active {
  }
  cursor: pointer;
  span {
    height: 16px;
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.disabled {
  background: #e2e2e2;
  cursor: not-allowed;
}

.label {
  font-size: 12px;
  line-height: 140%;
  color: rgba(9, 53, 79, 0.5);
}

.dropdown {
  width: 100%;
  position: absolute;
  // top: 100%;
  left: 0;
  background: white;
  border: 1px solid $colorBorderLightGrey;
  border-top: none;
  max-height: 140px;
  overflow-y: auto;
  z-index: 2;
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  text-align: center;
  &:hover {
    background: $colorBackGroundLightGrey;
    cursor: pointer;
  }
}

/* width */
.dropdown::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.dropdown::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.dropdown::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  transition: 0.2s all ease;
  font-size: 10px;
}
.reverse {
  transform: translateY(-50%) rotate(-180deg);
}
.required {
  @include star-required();
}
.error {
  border: 1px solid $colorError;
}

@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 442px;
  @include wrapper-modal-div;
  padding: 54px 40px 40px;
}

.wrapperTitle {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  & .form {
    width: 100%;
  }
}

.description {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: $grey;
  margin-bottom: 20px;
  margin-top: 10px;
}

@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  width: 100%;
  & textarea {
    width: 100%;
    height: 150px;
    resize: none;
  }

  & label {
    font-size: 12px;
    line-height: 140%;
    color: rgba(9, 53, 79, 0.5);
  }

  & textarea {
    border: 1px solid $colorBorderLightGrey;
    resize: none;
    outline: none;
    width: 100%;
    margin: 0 auto;
    display: block;
    padding: 9px 20px;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: $grey;
    &.fill {
      background: linear-gradient(0deg, #ffffff, #ffffff),
        rgba(180, 210, 222, 0.3);
      outline: 1px solid #2c98b0;
    }
  }
  & .errorTextarea {
    border: 1px solid $colorError;
  }
}
.required {
  font-size: 18px;
}

.error {
  @include error-fildes();
}

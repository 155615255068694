@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 522px;
  @include wrapper-modal-div;
  padding: 50px 60px 40px;
}

.h2 {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
}

@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 700px;
  @include wrapper-modal-div;

  & .wrapperTitle {
    display: flex;
    justify-content: center;
    // flex: 0 0 auto;
  }
  & .wrapperOrder {
    margin-top: 25px;
    // flex: 1 0 auto;
  }
  & .footer {
    margin-top: 25px;
    padding: 25px 60px;

    width: 100%;
    display: flex;
    align-items: center;
    // flex: 0 0 auto;

    justify-content: space-between;
    background: #f9f1eb;
    // & .btn {
    //   padding: 0 33px;
    // }
    & .back {
      margin-left: 50px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: $colorTextDarkgrey;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: $md) {
  .root {
    & .wrapperTitle {
    }
    & .wrapperOrder {
    }
    & .footer {
      flex-direction: column;
      padding: 20px 0 0;
      & .btn {
      }
      & .back {
        margin: 15px auto;
        z-index: 10;
      }
    }
  }
}

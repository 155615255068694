@import "src/styles/vars";

.root {
  background: #f9f1eb;
  z-index: 6;

  & .wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 7;
    & .categoryItem {
      &:hover {
        .categorySubRoot {
          display: block;
        }
      }

      & .categorySubRoot {
        display: none;
        position: absolute;
        width: 100%;
        height: max-content;
        // min-height: 250px;
        top: 100%;
        left: 0;
        z-index: 3;
        padding: 0 0 55px;
        // height: 100vh;
        // backdrop-filter: blur(2px);
        & .rootcategorySub {
          padding-left: 15px;
          padding-top: 40px;
          padding-bottom: 40px;
          background: $colorBackGroundWhite;

          & .wrapperCategorySub {
            width: 70%;

            & .titleGeneral {
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: #000000;
              margin-bottom: 8px;
            }

            & .innerCategorySub {
              margin-top: 15px;
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              & .categorySubItem {
                width: fit-content;
                margin-top: 10px;
                padding-bottom: 2px;
                font-size: 14px;
                line-height: 16px;
                color: $colorTextDarkgrey;
                text-decoration: none;
                display: block;
                &:hover {
                  cursor: pointer;
                  font-weight: 600;

                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }
  & .wrapperSearchInput {
    max-width: 500px;
    width: 100%;
    padding: 15px 0;
  }
}

.categoryItem {
  font-size: 15px;
  line-height: 18px;
  padding: 19px 0 19px;
  letter-spacing: 0.02em;
  color: $colorTextDarkgrey;
  margin-right: 35px;
  &:last-child {
    margin-right: 0;
  }

  & .mainCategory {
    color: $colorTextDarkgrey;

    &:hover {
      color: #000;
    }
  }
}
.categoryItemNews {
  font-weight: 600;
  color: $colorTextDarkgrey;
}
.categoryItemAction {
  font-weight: 600;
  color: $colorBackGroundRed;
}

.categoryItem,
.activeCategoryItem {
  font-size: 15px;
  line-height: 18px;
  color: $grey;

  cursor: pointer;
  // &::before {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   bottom: -3px;
  //   width: 100%;
  //   height: 2px;
  //   background: $colorBackGroundRed;
  //   border-radius: 2px;
  //   left: 50%;
  //   transition: 0.3s;
  //   transform: translateX(-50%) scale(0);
  //   transform-origin: left;
  // }
}

.activeCategoryItem {
  font-weight: 500;
  color: $colorTextDarkgrey;
  transform: translateX(-50%) scale(1);
}
.categoryWrapper {
  display: flex;
  align-items: center;
  margin-right: 30px;
  flex-wrap: wrap;
}

.categoryItemLink {
  width: max-content;
  &:hover {
    &::before {
      transform: translateX(-50%) scale(1);
    }
    font-weight: 600;
    color: $colorTextDarkgrey;
  }
}

.menuNew {
  display: flex;
  // justify-content: space-between;
  & .menuNewItem {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 20px 10px;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: $colorTextDarkgrey;
    margin-left: 2px;
    background-color: #fbfbfb;
    &:first-child {
      margin-left: 0;
    }

    img {
      height: 25px;
      max-width: 42px;
    }
    span {
      margin-top: 5px;
      display: block;
    }
  }
}

@media screen and (max-width: $lg) {
  .root {
    background: $colorBackGroundWhite;
    & .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .activeGendersCategoryItem {
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 15px;
      width: 100%;
      height: 2px;
      background: $colorBackGroundRed;
      border-radius: 2px;
      left: 50%;
      transition: 0.3s;
      transform: translateX(-50%) scale(1);
      // transform-origin: left;
    }
  }
}
@media screen and (max-width: $mdMinus) {
  .menuNew {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    gap: 2px;
    & .menuNewItem {
      margin: 0;
    }
  }
}
@media screen and (max-width: 400px) {
  .menuNew {
    & .menuNewItem {
      font-size: 10px;
    }
  }
}

@media screen and (max-width: $sm) {
  .root {
    & .wrapper {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & .categoryItem {
        margin-right: 0;
      }
    }
  }
  .menuNew {
    grid-template-columns: 1fr 1fr;

    & .menuNewItem {
      margin: 0;
    }
  }
}

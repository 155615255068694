@import "src/styles/vars";

.item {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  width: 100%;
  & .wrapperTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    & .name {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      color: $colorTextDarkgrey;
    }
    & .date {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      color: $colorTextDarkgrey;
    }
  }
  & .text {
    margin-top: 7px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: $colorTextDarkgrey;
  }
}

@import "src/styles/vars";

.sliderButton {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  outline: none;
  z-index: 4;
  transition: background 0.3s;
  cursor: pointer;
  color: $colorTextDarkgrey;
  span {
    display: none;
  }
}

@import "src/styles/vars";

.mobileMenu {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  z-index: 100;
  background-color: #fff;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  & .burgerWrapperTop {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 25px 10px 0;
  }
  .burgerWrapperTop {
    width: 100;
    background-color: #fff;
    & .burger {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      position: relative;
      &::before,
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 10px;
        top: 50%;
        left: 50%;
        background: $colorBackGroundDarkgrey;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    & .searchAdaptiveMobileMenu {
      display: flex;

      & input {
        margin-left: 15px;
        width: 100%;
        border-bottom: 1px solid rgba($color: #353535, $alpha: 0.25);
      }
    }
  }
  & .burgerWrapperMenu {
    margin-top: 20px;
    background-color: #fff;
    flex-grow: 1;
    & .wrapperButtons {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: flex-start;
      gap: 2px;
    }

    & .categoryWrapper {
      padding: 10px 0;

      & .categoryItemBack {
        display: flex;

        align-items: center;
        // justify-content: space-between;
        width: 100%;
        padding: 10px 8%;
        position: relative;
        font-size: 15px;
        line-height: 15px;
        letter-spacing: 0.02em;
        & .mainCategory {
          color: $colorTextDarkgrey;
        }
        & .iconCategory {
          height: 25px;
          max-width: 42px;
          margin-right: 25px;
        }
        & .arrowSvg {
          position: absolute;
          right: 20px;
          top: 32%;
          svg {
            width: 15px;
            height: 15px;
          }
          transform: rotate(180deg);
          transition: 0.7s transform;
        }
        & .arrowActiveSvg {
          position: absolute;
          right: 20px;
          top: 32%;

          svg {
            width: 15px;
            height: 15px;
          }
          transition: 0.7s transform;
          transform: rotate(0deg);
        }
      }
      & .activeCategoryItemBack {
        background-color: $colorBackGroundRed;

        & .mainCategory {
          color: #fff;
        }

        & .iconCategory {
          filter: brightness(5);
        }
      }
      & .categorySubRoot {
        // display: none;
        display: flex;
        flex-direction: column;
        & .categorySubItem {
          font-weight: 500;
          background-color: #fbfbfb;
          margin-top: 2px;
          padding: 10px 0 10px 36px;
          font-size: 14px;
          color: $colorTextDarkgrey;
        }
      }
    }
  }
}

@media screen and (max-width: $mdMinus) {
  .mobileMenu {
    & .burgerWrapperTop {
    }
    .burgerWrapperTop {
      & .burger {
        &.active {
        }
        span {
        }
      }
      & .searchAdaptiveMobileMenu {
      }
    }
    & .burgerWrapperMenu {
      & .wrapperButtons {
        grid-template-columns: 1fr 1fr;
      }

      & .categoryWrapper {
        & .categoryItemBack {
          & .mainCategory {
          }
          & .iconCategory {
          }
          & .arrowSvg {
          }
          & .arrowActiveSvg {
          }
        }
        & .activeCategoryItemBack {
          & .mainCategory {
          }

          & .iconCategory {
          }
        }
        & .categorySubRoot {
          & .categorySubItem {
          }
        }
      }
    }
  }
}

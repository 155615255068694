@mixin blob-base {
  overflow: hidden;
  border-radius: 70px;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: #fff;
  .blob-center {
    transform-style: preserve-3d;
    position: absolute;
    background: $bolb-color;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    transform-origin: left top;
    transform: scale(0.9) translate(-50%, -50%);
    animation: blob-grow linear 3.4s infinite;
    border-radius: 50%;
  }
}

@mixin blob-item {
  .blob {
    position: absolute;
    background: $bolb-color;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    animation: blobs ease-out 3.4s infinite;
    transform: scale(0.9) translate(-50%, -50%);
    transform-origin: center top;
    opacity: 0;

    // Set animation delay for each of type
    @for $i from 1 to 6 {
      &:nth-child(#{$i}) {
        animation-delay: $i * 0.2 + s;
      }
    }
  }
}

@mixin keyframes_blob-grow {
  @keyframes blob-grow {
    0%,
    39% {
      transform: scale(0) translate(-50%, -50%);
    }
    40%,
    42% {
      transform: scale(1, 0.9) translate(-50%, -50%);
    }
    43%,
    44% {
      transform: scale(1.2, 1.1) translate(-50%, -50%);
    }
    45%,
    46% {
      transform: scale(1.3, 1.2) translate(-50%, -50%);
    }
    47%,
    48% {
      transform: scale(1.4, 1.3) translate(-50%, -50%);
    }
    52% {
      transform: scale(1.5, 1.4) translate(-50%, -50%);
    }
    54% {
      transform: scale(1.7, 1.6) translate(-50%, -50%);
    }
    58% {
      transform: scale(1.8, 1.7) translate(-50%, -50%);
    }
    68%,
    70% {
      transform: scale(1.7, 1.5) translate(-50%, -50%);
    }
    78% {
      transform: scale(1.6, 1.4) translate(-50%, -50%);
    }
    80%,
    81% {
      transform: scale(1.5, 1.4) translate(-50%, -50%);
    }
    82%,
    83% {
      transform: scale(1.4, 1.3) translate(-50%, -50%);
    }
    84%,
    85% {
      transform: scale(1.3, 1.2) translate(-50%, -50%);
    }
    86%,
    87% {
      transform: scale(1.2, 1.1) translate(-50%, -50%);
    }
    90%,
    91% {
      transform: scale(1, 0.9) translate(-50%, -50%);
    }
    92%,
    100% {
      transform: scale(0) translate(-50%, -50%);
    }
  }
}

@mixin options-value-before {
  content: "";
  width: 80%;
  height: 1px;
  display: block;
  position: absolute;
  left: 50%;
  bottom: 1px;
  transform: translateX(-50%) scale(0);
  background: #ee4a2e;
  transition: 0.3s;
}

@mixin filter-before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -1px;
  width: calc(100% - 40px);
  height: 2px;
  background: $colorBackGroundRed;
  border-radius: 2px;
  transform: translateX(-50%) scale(0);
  transition: 0.4s linear;
  transform-origin: center;
}

@mixin filter-active {
  &::before {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }

  .body {
    max-height: 300px;
    padding: 20px;
    opacity: 1;
  }

  svg {
    top: 0;
  }
}

@mixin filter-name {
  min-width: fit-content;
  padding: 7px 15px 7px 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $darkgrey;
  cursor: pointer;

  svg {
    margin-left: 10px;
    margin-top: 3px;
    transition: 0.4s;
  }
}

@mixin filter-body {
  position: absolute;
  left: 50%;
  top: 100%;
  max-height: 0;
  width: fit-content;
  padding: 0 10px;
  background: $colorBackGroundWhite;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transform: translateX(-50%);
  transition: 0.4s;
  overflow: hidden;
  z-index: 3;
}

@mixin scrollbar {
  max-height: auto;
  overflow: auto;
  margin-bottom: 5px;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
    outline: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
    outline: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $colorBackGroundLightGrey;
    border-radius: 10px;
    outline: none;
  }
}

@mixin star-required() {
  font-size: 18px;
}

@mixin error-fildes() {
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(185, 40, 40);
  text-align: center;
  font-size: 12px;
}

@mixin wrapper-modal-div() {
  // overflow-y: auto;

  padding-top: 54px;
  width: 100%;
  position: relative;
  z-index: 4;
  border-radius: 5px;
  box-shadow: 4px 5px 30px rgba(212, 212, 235, 0.4);
  background: $colorBackGroundWhite;
}

@mixin text-overflow($lines) {
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin textOverflowOneLine() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

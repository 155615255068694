@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 522px;
  @include wrapper-modal-div;
  padding: 50px 60px 40px;
}

.wrapperTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  & div {
    display: flex;
    justify-content: center;
  }
}
.content {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-left: 5px;
  }
}

.btnInner {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-left: 5px;
  }
}

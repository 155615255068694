@import "src/styles/vars";
@import "src/styles/mixins";

.modalWrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  backdrop-filter: blur(5px);
  // @include scrollbar;

  &Inner {
    position: relative;
    // width: 100%;
    // max-width: 1000px;
    z-index: 6;
    border-radius: 5px;
    box-shadow: 4px 5px 30px rgba(212, 212, 235, 0.4);
    background: $colorBackGroundWhite;

    // @include scrollbar;
  }
}

.modalWrapper {
  height: 100%;
}
.background {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  opacity: 1;
  z-index: 5;
}

@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 442px;
  @include wrapper-modal-div;
  padding: 54px 40px;
}

.wrapper {
  max-width: 420px;
  width: 100%;
}
.wrapperInputs {
  display: flex;
  justify-content: space-between;
}

.wrapperTitle {
  width: 100%;
  display: flex;
  justify-content: center;
}

.text {
  margin-top: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: $grey;
  text-align: center;
}

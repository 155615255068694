@import "src/styles/vars";

.title {
  display: flex;
  position: relative;
  align-items: center;
  // padding-left: 60px;
}
.titleLine {
  transform: translateY(-50%);
  background: $colorBackGroundDarkgrey;
  border-radius: 5px;
}
.titleText {
  font-weight: bold;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $colorTextDarkgrey;
  margin-left: 20px;
}

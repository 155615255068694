@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  width: 100%;
  background: $colorBackGroundDarkgrey;
  position: relative;
}

.btnBack {
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
  svg {
    margin-right: 10px;
  }
}

.wrapperLogo {
  width: 114px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: $smPlus) {
    width: 86px;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 0;
}
.languagesWrapper {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.selectedLanguage {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-bottom: 5px;
  color: #a4a4a4;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
}

.languageText {
  margin-right: 10px;
  font-weight: 500;
  color: #ffffff;
}
.dropdownLanguage,
.activeDropdownLanguage {
  position: absolute;
  top: 100%;
  left: 50%;
  display: none;
  transform: translateX(-50%);
}
.activeDropdownLanguage {
  display: block;
  z-index: 5;
  background: $colorBackGroundDarkgrey;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 37px 15px 21px;
  & .selectedLanguage {
    border-bottom: 1px solid $colorBackGroundRed;
    background: $colorBackGroundRed;
  }
}
.arrowSvg,
.arrowSvgActive {
  transform: rotate(-180deg);
  margin-left: 10px;

  svg {
    width: 10px;
    height: 10px;
  }
  transition: 0.7s transform;
}

.lang {
  &::before {
    @include options-value-before;
  }
}
.langActive {
  &::before {
    transform: translateX(-50%) scale(1);
  }
}
.arrowSvgActive {
  transition: 0.7s transform;
  transform: rotate(0deg);
}
.languageBtn {
  padding-bottom: 10px;
  &:last-child {
    padding-bottom: 0;
  }
}

@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 522px;
  @include wrapper-modal-div;
  padding: 50px 60px 40px;
}

.wrapperTitle {
  width: 100%;
  display: flex;
  justify-content: center;
}
.form {
  width: 100%;
}
.btnLogIn {
  width: 100%;
}
.Btns {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.reg,
.regText {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 8px;
  column-gap: 8px;
  margin-top: 30px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.05em;
  text-decoration: none;
  text-transform: uppercase;
  color: $colorTextDarkgrey;
  cursor: pointer;
  p,
  span {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      background: $colorBackGroundDarkgrey;
    }
  }
}
.regText {
  margin-top: 30px;
  cursor: default;
}

.linkRegistration {
  display: flex;
  column-gap: 10px;
  color: #353535;
}

@media screen and (max-width: $smPlus) {
  .root {
    max-width: 522px;
    @include wrapper-modal-div;
    padding: 50px 25px 20px;
  }
  // .Btns {
  //   flex-direction: column;
  //   margin-top: 0;
  //   div {
  //     width: 100%;
  //   }
  // }
}

@import "src/styles/vars";

.root {
  padding: 10px 0;

  @media screen and (max-width: $smPlus) {
    position: fixed;
    width: 100%;
    max-width: 100vw;
    background: #fff;
    height: 60px;
    margin: 0;
    padding: 5px 5px 10px;
    top: 0;
    z-index: 55;
  }
}
.logoWrapper {
  position: absolute;
  height: 50px;
  left: 50%;
  transform: translateX(-50%);
  img {
    max-width: 150px;
  }

  @media screen and (max-width: $smPlus) {
    img {
      max-width: 117px;
    }
  }
}
.wrapper {
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.categoryItem,
.activeCategoryItem {
  font-size: 15px;
  line-height: 18px;
  color: $grey;
  padding-bottom: 3px;
  margin-right: 28px;
  position: relative;
  cursor: pointer;
  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background: $colorBackGroundRed;
    border-radius: 2px;
    left: 50%;
    transition: 0.3s;
    transform: translateX(-50%) scale(0);
    transform-origin: left;
  }
  &:hover {
    &::before {
      transform: translateX(-50%) scale(1);
    }
    font-weight: 600;
    color: $colorTextDarkgrey;
  }
}

.activeCategoryItem {
  &::before {
    transform: translateX(-50%) scale(1);
  }
  font-weight: 600;
  color: $colorTextDarkgrey;
  &:hover {
    font-weight: 600;
    color: $colorTextDarkgrey;
  }
}
.buttonProfileWrapper {
  display: flex;
  align-items: center;
}

.burger {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  position: relative;
  &.active {
    span {
      background: #fff;
      &::before,
      &::after {
        width: 100%;
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
  span {
    width: 20px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -1px;
    background: $colorBackGroundDarkgrey;
    border-radius: 10px;
    transition: 0.3s;
    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 15px;
      height: 2px;
      top: 50%;
      left: 60%;
      margin-left: -10px;
      margin-top: -1px;
      background: $colorBackGroundDarkgrey;
      border-radius: 10px;
      transition: 0.3s;
    }
    &::before {
      transform: translateY(-5px);
    }
    &::after {
      transform: translateY(5px);
    }
  }
}

.burgerWrapper {
  display: flex;
}

.searchAdaptive {
  position: absolute !important;
  background-color: #ffffff !important;
  margin-left: 20px;
  max-width: 0%;
  z-index: 5;
  display: flex;
  input {
    border-bottom: 1px solid rgba($color: #353535, $alpha: 0.25);
  }
}

.searchAdaptiveMobileMenu {
  margin-left: 10px;
  margin-right: 15px;
  display: flex;
  z-index: 10 !important;
  position: static !important;
  & input {
    margin-left: 15px;
    width: 100%;
    border-bottom: 1px solid rgba($color: #353535, $alpha: 0.25);
  }
}
.searchAdaptiveMobileMenuDropdown {
  position: absolute !important;
  top: 7% !important;
  left: 0 !important;
  max-width: 100% !important;
  width: 100vw !important;
  @media screen and (max-width: $smPlus) {
    top: 100% !important;
  }
}

@media screen and (max-width: 1260px) {
  .logoWrapper {
    position: static;
    left: 0;
    transform: translateX(0);
  }
}
@media screen and (max-width: $mdMinus) {
  .wrapperButtons {
    grid-template-columns: 1fr 1fr;
  }
}

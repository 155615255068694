@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 900px;

  @include wrapper-modal-div;
  padding: 0 10px 50px 10px;
  margin: 10px;
  height: auto;
  overflow-y: auto;

  & .wrapperTitle {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  & .titleList {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
    margin-top: 20px;
    position: relative;
    padding-bottom: 11px;
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      bottom: 0;
      left: 0;
      height: 1px;
      background: $colorBackGroundLightGrey;
      border-radius: 1px;
    }
    & .titleListItem {
      min-width: 80px;
      width: 80px;
      margin: 0 17px;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $grey;
      text-align: center;
    }
  }

  & .list {
    margin-top: 3px;
    width: 100%;
    & .listItem {
      padding: 12px 0 13px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $colorTextDarkgrey;
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      &::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        bottom: 0;
        left: 0;
        height: 1px;
        background: $colorBackGroundLightGrey;
        border-radius: 1px;
      }
      span {
        text-align: center;
        min-width: 90px;
        width: 90px;
        margin: 0 3px;
      }
    }
  }
  & .content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    & .text {
      color: $colorTextDarkgrey;
      width: 49%;
      font-size: 14px;
      line-height: 22px;
    }
  }
  & .wrapperSubtitle {
    margin-top: 20px;
  }
  & .wrapperImg {
    margin-left: 2%;
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: $md) {
  .root {
    & .wrapperTitle {
    }
    & .table {
      overflow-x: scroll;
    }
    & .titleList {
      // overflow-x: scroll;
      &::before {
      }
      & .titleListItem {
      }
    }
    & .list {
      // overflow-x: scroll;
      & .listItem {
        &::before {
        }
        span {
        }
      }
    }
    & .content {
      flex-direction: column-reverse;

      width: 100%;
      & .text {
        width: 100%;
      }
      & .wrapperImg {
        margin: 10px auto;
        max-width: 400px;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    & .wrapperSubtitle {
    }
  }
}

@import "src/styles/vars";

.root {
  margin-top: 15px;
  margin-bottom: 2px;
  width: 100%;
  display: flex;
  overflow: hidden;
  text-decoration: none;

  & .wrapperImg {
    width: 72px;
    height: 72px;
    min-width: 72px;
    min-height: 72px;
    margin-right: 15px;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  & .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-row-gap: 14px;
    row-gap: 14px;
    max-width: 500px;
    width: 100%;
    & .wrapperNameRemove {
      display: flex;
      position: relative;
      & .name {
        padding-right: 16px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
        color: $colorTextDarkgrey;
      }
      & .remove {
        font-size: 16px;
        line-height: 24px;
        position: absolute;
        right: 6px;
        top: 6px;
        cursor: pointer;

        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 2px;
          height: 14px;
          background: $grey;
          border-radius: 50px;
        }
        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
    & .info {
      display: flex;
      justify-content: space-between;
      grid-column-gap: 20px;
      column-gap: 20px;
      align-items: center;
      max-width: 576px;
      width: 100%;
      & .size {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 30%;
        & .sizeItem {
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: $grey;
        }
        & .sizePoint {
          width: 100%;
          margin-left: 10px;
          display: flex;
          align-items: center;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: $colorTextDarkgrey;
        }
      }
      & .counter {
        display: flex;
        align-items: center;
        border: 1px solid #eaeaea;
        border-radius: 2px;
        margin-right: 30px;
        user-select: none;
        width: fit-content;
        & .minus,
        & .quantity,
        & .plus {
          width: 27px;
          height: 30px;
          font-size: 16px;
          line-height: 30px;
          text-align: center;
          letter-spacing: 0.02em;
          color: $colorTextDarkgrey;
        }

        & .minus,
        & .plus {
          cursor: pointer;
        }

        & .cost {
          max-width: 106px;
          width: 100%;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.05em;
          color: $colorTextDarkgrey;
        }
      }
    }
  }
}

@media screen and (max-width: $smPlus) {
  .root {
    display: flex;
    // flex-direction: column;
    & .wrapperImg {
      img {
      }
    }
    & .body {
      margin-top: 10px;
      & .wrapperNameRemove {
        & .name {
        }
        & .remove {
        }
      }
      & .info {
        & .size {
          & .sizeItem {
          }
          & .sizePoint {
          }
        }
        & .counter {
          & .minus,
          & .quantity,
          & .plus {
          }

          & .minus,
          & .plus {
            cursor: pointer;
          }

          & .cost {
          }
        }
      }
    }
  }
}

.sizeCounter {
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  width: 100%;
  @media screen and (max-width: $smPlus) {
    flex-direction: column;
    & .size {
      margin-bottom: 5px;
    }
  }
}

@import 'src/styles/vars';
@import 'src/styles/mixins';

.root {
  max-width: 420px;
  @include wrapper-modal-div;
  padding: 54px 40px;
}

.wrapperInputs {
  display: flex;
  justify-content: space-between;
}

.wrapperTitle {
  width: 100%;
  display: flex;
  justify-content: center;
}

.passwordText {
  margin-top: 10px;
  width: 100%;
  color: $grey;
  font-size: 11px;
}

.wCaptcha {
  width: max-content;
  margin: 15px auto 0;
}

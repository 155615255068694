@import "src/styles/vars";

.burgerButtonItem {
  position: relative;
  display: flex;
  justify-content: center;
  background: #fbfbfb;
  padding: 19px 0;
  color: $colorTextDarkgrey;
  & .burgerButtonItemSvg {
    padding-right: 10px;
  }
}
.burgerButtonItemTitle {
  position: relative;
}

.counts {
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  position: absolute;
  right: -14px;
  top: -10px;
  background: $colorBackGroundRed;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  z-index: 1;
}

.wrapperProfile {
  position: relative;

  & .activeButtonItem {
    & .buttonItemTitle {
      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: -3px;
        width: 100%;
        height: 2px;
        background: $colorBackGroundRed;
        border-radius: 2px;
        left: 50%;
        transition: 0.3s all;
        transform: translateX(-50%) scale(1);
        // transform-origin: left;
      }
    }
  }
  & .subLinks {
    width: max-content;
    max-height: 0;
    transition: 0.5s;
    position: absolute;
    overflow: hidden;
    top: 110%;
    z-index: 6;
    display: flex;
    flex-direction: column;
    background: $colorBackGroundWhite;
    // padding: 10px 25px 10px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    left: 50%;
    transform: translateX(-50%);
    & .subLinkItem {
      &:first-child {
        padding-top: 20px;
      }
    }
    & .subLinkItem,
    & .subLinkItemOut {
      width: 100%;
      padding: 10px 25px;
      font-size: 13px;
      color: $colorTextDarkgrey;
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }
    & .subLinkItemOut {
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.05em;
      align-items: center;
      text-decoration-line: underline;
    }
    & .subLinkItem {
      &:hover {
        font-weight: 600;
      }
    }
  }
  &:hover {
    & .subLinks {
      max-height: 300px;
    }
    & .activeButtonItem {
      & .buttonItemTitle {
        &::before {
          content: "";
          display: block;
          position: absolute;
          bottom: -3px;
          width: 100%;
          height: 2px;
          background: $colorBackGroundRed;
          border-radius: 2px;
          left: 50%;
          transition: 0.3s all;
          transform: translateX(-50%) scale(1);
          // transform-origin: left;
        }
      }
    }
  }
}

@media screen and (max-width: 1680px) {
  .wrapperProfile {
    position: relative;

    &:hover {
      & .subLinks {
        display: none;
      }
    }
  }
}

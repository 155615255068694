@import "src/styles/vars";

.checkboxItem {
  display: flex;

  & label {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: $colorTextDarkgrey;
    &.error {
      // padding-top: 10px;
      color: rgb(185, 40, 40);
    }
  }
}

.checkbox {
  min-width: 15px;
  min-height: 15px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: center;
  border: 1px solid #d3d3d3;
  transition: all 0.3s linear;
  cursor: pointer;
  input {
    display: none;
    width: 100%;
    height: 100%;
  }
  &.active {
    background: $grey;
    border: none;
  }
  img {
    width: 90%;
    height: 60%;
  }
}

.required {
  font-size: 18px;
}

// .error {
//   // padding-top: 10px;
//   color: rgb(185, 40, 40) !important;
// }

@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 555px;
  @include wrapper-modal-div;
  padding: 50px 0px 40px;
  & .content {
    position: relative;
    padding: 20px 13px 21px 30px;
    margin: 15px 0;
    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: $colorBackGroundLightGrey;
      border-radius: 10px;
      position: absolute;
      left: 0;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
    & .back {
      width: 36px;
      height: 19px;
      position: absolute;
      top: 1px;
      left: 50%;
      transform: translate(-50%);
      background: $colorBackGroundLightGrey;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a4a4a4;
      font-size: 12px;
    }
    & .chat {
      width: 100%;
      padding-right: 34px;
      overflow-y: scroll;
      max-height: 284px;
    }
  }
  & .wrapper {
    padding: 0 20px;
  }
}

.textarea {
  border: 1px solid $colorBorderLightGrey;
  resize: none;
  outline: none;
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 9px 20px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: $grey;
  &.fill {
    background: linear-gradient(0deg, #ffffff, #ffffff),
      rgba(180, 210, 222, 0.3);
    outline: 1px solid #2c98b0;
  }
}

.wrapperTitle {
  width: 100%;
  display: flex;
  justify-content: center;
}
.wrapper {
  // margin: 0 30px;
  width: 100%;
}
.from {
  // margin: 0 30px;
}

@import "src/styles/vars";

.root {
  border-top: 1px solid #fbfbfb;
  padding: 14px 0 18px;
  width: 100%;
}
.paytmentMethod {
  margin-right: 15px;
}
.wrapper {
  width: 100%;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .paytment {
    display: flex;
  }
}
.savingRights {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  span {
    padding-left: 10px;
  }
}

.socialsWrapper {
  display: flex;
  justify-content: center;
}
.socialSvg {
  svg {
    path {
      fill: #e65151;
    }
    &:first-child {
      margin-right: 15px;
      width: 22px;
      height: 22px;
    }
  }
}
@media screen and (max-width: $lg) {
  .root {
    padding: 14px 0 18px;
  }
  .paytmentMethod {
    width: 40px;
  }
  .socialSvg {
    svg {
      path {
        fill: #e65151;
      }
      &:first-child {
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }
}

@import "src/styles/vars";
@import "src/styles/mixins";

.logoAndLanguage {
  max-width: 170px;
  font-size: 14px;
  line-height: 17px;
  color: $grey;
  margin-right: 150px;

  & .languagesWrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    & .languageText {
      margin-right: 10px;
      font-weight: 500;
    }
    & .selectedLanguage {
      display: flex;
      padding-bottom: 5px;
      text-transform: uppercase;
      position: relative;
      cursor: pointer;
      & .lang {
        &::before {
          @include options-value-before;
        }
      }
      & .langActive {
        &::before {
          transform: translateX(-50%) scale(1);
        }
      }
      & .arrowSvg,
      & .arrowSvgActive {
        transform: rotate(-180deg);
        margin-left: 10px;

        svg {
          width: 15px;
          height: 15px;
        }
        transition: 0.7s transform;
      }

      & .arrowSvgActive {
        transition: 0.7s transform;
        transform: rotate(0deg);
      }
      & .dropdownLanguage,
      & .activeDropdownLanguage {
        position: absolute;
        top: 100%;
        left: 50%;
        display: none;
        transform: translateX(-50%);
        & .languageBtn {
          padding-bottom: 10px;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      & .activeDropdownLanguage {
        display: block;
        z-index: 5;
        background: $colorBackGroundWhite;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        padding: 10px 21px 15px 21px;
        & .selectedLanguage {
          border-bottom: 1px solid $colorBackGroundRed;
          background: $colorBackGroundRed;
        }
      }
    }
  }
  & .savingRights {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & .socialsWrapper {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      & .socialSvg {
        svg {
          path {
            fill: #e65151;
          }
          &:first-child {
            margin-right: 15px;
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $lg) {
  .logoAndLanguage {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    margin-right: 0;
    position: relative;

    .wrapperLogo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    & .mainLogoSvg {
      width: 117px;
      svg {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .logoAndLanguage {
    flex-direction: column;
  }
}

@import "src/styles/vars";
.rootThank {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $colorTextDarkgrey;
}
.img {
  margin-top: 20px;
  width: 100%;
}
.wrapperImg {
  max-width: 800px;
}

@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 442px;
  @include wrapper-modal-div;
  padding: 54px 40px;
}

.wrapper {
  max-width: 420px;
  width: 100%;
}
.wrapperInputs {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.wrapperTitle {
  width: 100%;
  display: flex;
  justify-content: center;
}

.lableInner {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
  text-transform: uppercase;
  color: #a4a4a4;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
}

.rating {
  width: fit-content;
  margin: 15px auto 25px;
}
.errorRating {
  border: 1px solid $colorError;
}

.btnInner {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-left: 5px;
  }
}

@media screen and (max-width: $smPlus) {
  .root {
    padding: 54px 20px 20px;
  }
  .wrapperInputs {
    flex-direction: column;
  }
}

@import "../styles/reset.scss";
@import "../styles/fonts.scss";
@import "../styles/mixins.scss";
@import "../styles/vars.scss";

html {
  scroll-behavior: smooth;
}
body {
  font-family: "SFProDisplay", sans-serif;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
  transition: 0.3s all;
}

main {
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

img[alt] {
  @include text-overflow(2);
}

.swiper-slide {
  display: flex;
  justify-content: center;
}

.swiper-pagination-bullet {
  &:not(:last-child) {
    margin-right: 10px;
  }
  margin-bottom: 10px;
  cursor: pointer;
  display: block;
  width: 20px;
  height: 5px;
  border-radius: 10px;
  padding: 2px;
  background: $colorBackGroundDarkgrey;
  opacity: 10;
}

.swiper-pagination-bullet-active {
  background: #f75d5d;
}

.mainPromoSliderPagination {
  .swiper-pagination-bullet-active {
    background: #f9f1eb;
  }
}

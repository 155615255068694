@import "src/styles/mixins";
@import "src/styles/vars";

$bolb-color: $colorBackGroundRed;

.blobs {
  filter: url(#goo);
  width: 100%;
  height: 200px;
  margin-top: 50px;
  @include blob-base;
  background: rgba(0, 0, 0, 0);
}
// Blob item
@include blob-item;

// Keyframes variables
$left: calc(-330px - 50%);
$right: calc(330px - 50%);

// Keyframes
@keyframes blobs {
  0% {
    opacity: 0;
    transform: scale(0) translate($left, -50%);
  }
  1% {
    opacity: 1;
  }
  35%,
  65% {
    opacity: 1;
    transform: scale(0.9) translate(-50%, -50%);
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(0) translate($right, -50%);
  }
}

@include keyframes_blob-grow;

@import "src/styles/vars";

.button {
  cursor: pointer;
  text-decoration: none;
  // transition: all 250ms ease;
  // transform-style: preserve-3d;
  line-height: 40px;
  height: 40px;
  width: 100%;
  border: 0;
  padding: 0;
  outline: none;

  .front,
  .back {
    text-align: center;
    width: 100%;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;

    letter-spacing: 0.1em;
  }

  .front {
    background-color: $colorTextDarkgrey;
  }

  // .back {
  //   background-color: #515151;
  //   // transform: rotateX(90deg) translateZ(60px);
  // }

  &.red {
    .front {
      background-color: $colorBackGroundRed;
    }

    // .back {
    //   background-color: #fc654c;
    // }
  }

  &.white {
    .front {
      background-color: transparent;
      color: $colorTextDarkgrey;
    }

    .back {
      background-color: transparent;
      color: $colorTextDarkgrey;
    }
  }

  &.grayWhite {
    .front {
      background-color: #eaeaea;
      color: $colorTextDarkgrey;
    }

    // .back {
    //   background-color: white;
    //   color: $colorTextDarkgrey;
    // }
  }
  // &:hover {
    // transform: rotateX(-90deg);

    // .front {
    //   transition-delay: 250ms;
    //   opacity: 0;
    // }
  // }
}
.disabledLoading {
  cursor: progress;
}

@media screen and (max-width: $smPlus) {
  .button {
    .front,
    .back {
      font-size: 12px;
    }
  }
}

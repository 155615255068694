@import "src/styles/vars";

.footer {
  width: 100%;
  padding: 22px 0 10px;
  background: #e5e5e5;
  box-shadow: inset 0 4px 25px rgba(203, 203, 203, 0.27);
}

.body {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
}

.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #a4a4a4;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 113px;
  transform: translate(-50%, -50%);

  img {
    width: 100%;
  }
}

@media screen and (max-width: $tablet-media) {
  .footer {
    padding: 0;
  }

  .text {
    max-width: 80px;
    font-size: 9px;
    line-height: 10px;
  }
  .logo {
    width: 84px;
  }
}

@import "src/styles/vars";

.footerMenu {
}
.menu {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  margin-left: 20px;
  column-gap: 60px;
}

.menuItemTitle {
  font-size: 16px;

  color: $grey;
}

.linkMenu {
  margin-top: 20px;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: $grey;
}

.linkMenuItem {
  margin-bottom: 12px;
}
.isSublinkMenu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

.linkTitle {
  color: $grey;
  &:hover {
    color: #000000;
  }
}

.linkMenuNotActive {
  max-height: 0;
  overflow: hidden;
  transition: 0.6s linear;
}
.linkMenuActive {
  max-height: 1000px;
}
.attention {
  color: $colorBackGroundRed;
  &:hover {
    color: #f14242;
  }
}

@media screen and (max-width: $lg) {
  .menu {
    margin-top: 15px;
    grid-template-columns: 1fr;
    margin-left: 0;
    padding: 0 10px;
  }
  .isSublinkMenu {
    grid-template-columns: 1fr;
  }
  .aditionalLinkTitle {
    padding-top: 10px;
  }
  .wrapperTitle {
    border-top: 1px solid #fbfbfb;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .menuItemTitle {
    text-align: center;
    padding: 10px;
  }
  .arrowSvg {
    position: absolute;
    right: 10px;
    top: 20%;
    svg {
      width: 15px;
      height: 15px;
    }
    transform: rotate(180deg);
    transition: 0.7s transform;
  }
  .arrowActiveSvg {
    position: absolute;
    right: 10px;
    top: 20%;
    svg {
      width: 15px;
      height: 15px;
    }
    transition: 0.7s transform;
    transform: rotate(0deg);
  }
  .linkMenuItem {
    margin-left: 20px;
  }
}

@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 442px;
  @include wrapper-modal-div;
  padding: 54px 40px 40px;
}

.wrapperTitle {
  width: 100%;
  display: flex;
  justify-content: center;
}
.btnRepeat {
  max-width: 230px;
  width: 100%;
  line-height: 17px;
  background: white;
  margin-top: 20px;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: $colorTextDarkgrey;
  padding-bottom: 1px;

  cursor: pointer;
  span {
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      background: $colorBackGroundDarkgrey;
      left: 0;
      bottom: 0;
    }
  }
}
.groupsBtn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btnLogIn {
  width: 100%;
  max-width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  max-width: 362px;
  width: 100%;
  & .done {
    margin-top: 5px;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: $colorTextLightGeen;
    font-size: 13px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

.description {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: $grey;
  margin-bottom: 20px;
  margin-top: 10px;
}

@import "src/styles/mixins";
@import "src/styles/vars";

.root {
  width: 100%;
  display: inline-flex;
  flex-direction: column;

  & label {
    font-size: 12px;
    line-height: 140%;
    color: rgba(9, 53, 79, 0.5);
  }

  & .wrapperInput {
    position: relative;
    width: 100%;
    & input {
      width: 100%;
      border: 1px solid $colorBorderLightGrey;
      background: transparent;
      outline: none;
      padding: 11px 20px;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: $grey;
      box-shadow: none;
    }

    & .icon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
  & .errorInput {
    & input {
      border: 1px solid $colorError;
    }
  }
}
.required {
  @include star-required();
}

.error {
  @include error-fildes();
}

$xm: "1520px";
$xl: "1440px";
$lg: "1100px";
$md: "768px";
$mobile: "600px";
$mdMinus: "576px";
$smPlus: "480px";
$sm: "320px";

$white: #ffffff;
$tablet-media: 1100px;
$darkgrey: #353535;
$lightgrey: #a4a4a4;
$grey: #6d6d6d;

$colorBackGroundWhite: #ffffff;

$colorTextRed: #ee4a2e;
$colorBackGroundRed: #ee4a2e;
$colorTitleRed: #ec4c37;

$colorBackGroundLightGrey: #eaeaea;

$colorBorderLightGrey: #a4a4a4;

$colorTextLightGeen: #35cb44;

$colorTextDarkgrey: #353535;
$colorBackGroundDarkgrey: #353535;

$colorError: rgb(185, 40, 40);

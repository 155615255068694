@import "src/styles/vars";

.root {
  max-width: 1217px;
  margin: 0 auto;
}

@media screen and (max-width: $xl) {
  .root {
    max-width: 100%;
  }
}

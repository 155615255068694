@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 547px;
  width: 100%;
  display: flex;
  position: relative;
  z-index: 2;
  & .wrapperBody {
    display: flex;
    width: 100%;
    &:hover {
      .dropdown {
        border: 1px solid #fbfbfb;
        max-height: 320px;
      }
    }
    & .wrapperInput {
      display: flex;
      width: 100%;
      & input {
        width: 100%;
        border: none;
        outline: none;
        background: transparent;
        padding-left: 20px;
        padding-bottom: 6px;
        border-bottom: 1px solid rgba($color: #353535, $alpha: 0.25);
      }
    }

    & .dropdown {
      max-width: 547px;
      width: 100%;
      position: absolute;
      box-sizing: border-box;
      max-height: 0;
      overflow: hidden;
      top: 28px;
      position: absolute;

      background: #fff;
      transition: max-height 0.4s;
      overflow-y: scroll;
      z-index: 8;

      box-shadow: 0 30px 30px rgb(0 0 0 / 5%);
      & .item {
        padding: 10px 20px;
        text-decoration: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover {
          background: $colorBackGroundLightGrey;
          cursor: pointer;
        }
        & .wrapperImgName {
          display: flex;
          & .img {
            width: 25px;
            height: 35px;
            min-width: 25px;
            min-height: 35px;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          & .text {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $colorTextDarkgrey;
            max-width: 390px;
            width: 100%;
            @include text-overflow(2);
          }
        }
        & .cost {
          display: flex;
          align-items: flex-end;
          margin-left: 10px;

          p {
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $colorTextDarkgrey;
          }

          span {
            font-weight: 600;
            font-size: 8px;
            line-height: 10px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $colorTextDarkgrey;
            margin-left: 3px;
          }
        }
      }
    }
  }
  & .lupa {
    cursor: pointer;
  }
}

.input {
  display: none;
}

.activeSearch {
  max-width: 100%;
  input {
    display: block;
    // max-width: 547px;
    // width: 100%;
  }
}
.notActiveSearch {
  margin-left: 25px;
  max-width: 0px;
  & .dropdown {
    padding: 0 !important;
    border: none !important;
  }
}

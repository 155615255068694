@import "src/styles/vars";

.buttonItem {
  display: flex;
  align-items: center;
  margin-right: 28px;
  position: relative;
}

.counts {
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  position: absolute;
  right: -10px;
  top: -15px;
  background: $colorBackGroundRed;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  z-index: 1;
}

.buttonItemSvg {
  margin-right: 10px;
}

.buttonItemTitle {
  position: relative;
  color: #3f3f3f;
  cursor: pointer;
  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background: $colorBackGroundRed;
    border-radius: 2px;
    left: 50%;
    transition: 0.3s all;
    transform: translateX(-50%) scale(0);
  }
  &:hover {
    &::before {
      transform: translateX(-50%) scale(1);
    }

    color: $colorTextDarkgrey;
  }

  &:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: $lg) {
  .buttonItem {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  .buttonItemSvg {
    margin-right: 0;
  }

  .countsAdaptive {
    right: -8px;
    top: -15px;
  }
}

@import "src/styles/vars";

.headerTopAdvantages {
  display: flex;
  justify-content: center;
  padding: 17px 0;

  & .advantage {
    position: relative;
    display: flex;

    align-items: baseline;
    margin-right: 35px;
    color: $colorTextDarkgrey;
    cursor: pointer;

    // &:hover {
    //   .advantageDescription {
    //     transform: translateX(-50%) translateY(0%);
    //     visibility: visible;
    //     opacity: 1;
    //     z-index: 10;
    //   }
    // }
    & .advantageDescription {
      max-width: 224px;
      width: 100%;

      display: block;
      position: absolute;
      z-index: 3;
      visibility: hidden;
      top: calc(100% + 8px);
      background: $colorBackGroundWhite;
      font-size: 12px;
      line-height: 16px;
      font-weight: normal;
      text-transform: none;
      padding: 10px;
      letter-spacing: normal;
      color: $colorTextDarkgrey;
      left: 50%;
      transform: translateX(-50%) translateY(10%);
      opacity: 0;
      transform-origin: top center;
      transition: 0.3s;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

      span {
        display: block;
        // width: 100%;
      }
    }

    & .advantageText {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $colorTextDarkgrey;
      position: relative;
      width: 100%;

      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: -9px;
        width: 80%;
        height: 2px;
        background: $colorBackGroundRed;
        border-radius: 2px;
        left: 50%;
        transition: 0.3s;
        transform: translateX(-50%) scale(0);
        transform-origin: left;
      }
      &:hover {
        &::before {
          transform: translateX(-50%) scale(1);
        }
      }
    }
    & .svg {
      margin-right: 15px;
    }
  }
}

.headerTopAdvantages {
  & .advantage {
    & .activeAdvantageText {
      &::before {
        transform: translateX(-50%) scale(1);
      }
    }

    & .activeAdvantageDescription {
      transform: translateX(-50%) translateY(0%);
      visibility: visible;
      opacity: 1;
      z-index: 10;
    }
  }
}

@media screen and (max-width: $md) {
  .headerTopAdvantages {
    width: 100%;
    grid-column-gap: 10%;
    column-gap: 10%;
    flex-wrap: wrap;
    & .advantage {
      margin-right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45%;
      margin: 0;
      margin-bottom: 10px;
    }
  }
}
@media screen and (max-width: $mdMinus) {
  .headerTopAdvantages {
    width: 100%;
    grid-column-gap: 10%;
    padding: 10px;
    & .advantage {
      & .advantageDescription {
        // width: 100%;
      }
    }
  }
}
@media screen and (max-width: $sm) {
  .headerTopAdvantages {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

@import "/src/Styles/vars";
@import "src/styles/mixins";

.wrapperSearchList {
  position: relative;
  width: 100%;

  & .searchbar {
    position: relative;
    width: 100%;
    background: #f3f3f3;

    & .input {
      width: 100%;
      border: 1px solid $lightgrey;
      background: white;
      outline: none;
      padding: 11px 20px;
      font-size: 14px;
      letter-spacing: 0.05em;
      color: $grey;
      box-shadow: none;
      position: relative;
    }

    & .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      transition: 0.2s all ease;
      font-size: 10px;
    }
    & .reverse {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  & .dropdown {
    width: 100%;
    position: absolute;
    top: 39px;
    left: 0;
    background: white;
    border: 1px solid $lightgrey;
    max-height: 140px;
    overflow-y: auto;
    z-index: 2;
  }

  & .dropdown::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  & .dropdown::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  & .dropdown::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  & .dropdown::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.label {
  font-size: 12px;
  line-height: 140%;
  color: rgba(9, 53, 79, 0.5);
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  text-align: center;
  &:hover {
    background: $colorBackGroundLightGrey;
    cursor: pointer;
  }
}

.error {
  @include error-fildes();
}

.wrapperSearchList {
  .errorInput {
    & .input {
      border: 1px solid $colorError;
    }
  }
}

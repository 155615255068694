@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 670px;
  @include wrapper-modal-div;
  padding: 0;
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}

.slider {
  width: 100%;
  height: 100%;
}

.btnPrev,
.btnNext {
  z-index: 10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #fafafa !important;
  &:hover {
    background: #fafafa !important;
  }
}
.btnPrev {
  left: 0;
}
.btnNext {
  right: 0;
}

@import "src/styles/vars";

.root {
  background: $colorBackGroundDarkgrey;
  padding: 3px 0;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.socialLink {
  &:first-child {
    // margin-right: 5px;
  }
}
.languagesWrapper {
  display: flex;
}
.languageBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: 1.2rem;

  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  color: #a4a4a4;

  cursor: pointer;
  padding: 5px;
  &.activeBtn {
    color: #ffffff;
  }
}
@media screen and (max-width: $lg) {
  .socialLink {
    svg {
      width: 20px;
      height: 20px;
      path {
      }
    }
  }
}
// @media screen and (max-width: $smPlus) {
//   .root {
//     height: 50px;
//   }
// }

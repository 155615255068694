@import "src/styles/vars";
@import "src/styles/mixins";

.root {
  max-width: 442px;
  @include wrapper-modal-div;
  padding: 54px 40px;

  overflow-y: auto;

  position: relative;
  z-index: 7;
  border-radius: 5px;
  box-shadow: 4px 5px 30px rgba(212, 212, 235, 0.4);
  background: $colorBackGroundWhite;
}

.wrapper {
  max-width: 420px;
  width: 100%;
}
.wrapperInputs {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}

.wrapperTitle {
  width: 100%;
  display: flex;
  justify-content: center;
}

.errorRating {
  border: 1px solid $colorError;
}

.lableInner {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
  text-transform: uppercase;
  color: #a4a4a4;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
}

.rating {
  width: fit-content;
  margin: 15px auto 25px;
}

.btnInner {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-left: 5px;
  }
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 10px;
  column-gap: 10px;
  width: 100%;
  padding: 5px 10px 5px 0;
  &:hover {
    background: $colorBackGroundLightGrey;
    cursor: pointer;
  }

  & .wrapperImg {
    width: 25px;
    height: 35px;
    min-width: 25px;
    min-height: 35px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  & .name {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $colorTextDarkgrey;
    max-width: 390px;
    width: 100%;
  }
}

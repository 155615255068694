@import "src/styles/vars";

.root {
  display: flex;
}
.advantage {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 50px;

  cursor: pointer;

  &:hover {
    .advantageDescription {
      transform: translateX(-50%) translateY(0%);
      visibility: visible;
      opacity: 1;
      z-index: 10;
    }
  }
}
.advantageDescription {
  width: 224px;
  display: block;
  position: absolute;
  z-index: 3;
  visibility: hidden;
  top: calc(100% + 8px);
  background: $colorBackGroundWhite;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  text-transform: none;
  letter-spacing: normal;
  color: $colorTextDarkgrey;
  left: 50%;
  transform: translateX(-50%) translateY(10%);
  opacity: 0;
  transform-origin: top center;
  transition: 0.3s;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

  span {
    margin: 10px;
    display: block;
  }
}

.advantageText {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -9px;
    width: 80%;
    height: 2px;
    background: $colorBackGroundRed;
    border-radius: 2px;
    left: 50%;
    transition: 0.3s;
    transform: translateX(-50%) scale(0);
    transform-origin: left;
  }
  &:hover {
    &::before {
      transform: translateX(-50%) scale(1);
    }
  }
}

.svg {
  margin-right: 15px;
}

@import "src/styles/vars";

.footerMainInfo {
  width: 100%;
  padding: 48px 0 50px;
  box-shadow: inset 0px 4px 25px rgba(203, 203, 203, 0.27);
  & .wrapper {
    display: flex;
  }
}

@media screen and (max-width: $lg) {
  .footerMainInfo {
    box-shadow: none;
    padding: 5px 0 0;
    & .wrapper {
      flex-direction: column;
    }
  }
}
